import * as React from 'react';
import '../Design/Footer.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import CopyrightIcon from '@mui/icons-material/Copyright';

function Footer() {


    return (
      <>
      <div id='footer' >
      <div className="flex-center" style={{height:'5vh', paddingTop:'12vh'}}>
      <a href="https://www.linkedin.com/in/lirim-hamiti-33a35b245/" target="_blank" rel="noopener noreferrer">
        <i className="icon"><LinkedInIcon style={{ fontSize: '4vh', color:'white' }} /></i>
      </a>
      <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
        <i className="icon"><InstagramIcon  style={{ fontSize: '3.5vh' }} /></i>
      </a>
      <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
        <i className="icon"><FacebookIcon style={{ fontSize: '4vh', color:'white' }} /></i>
      </a>
    </div>
 <div className="flex-center2" style={{paddingTop:'2vh'}}>
<p>All rights reserved  <CopyrightIcon/> </p> 
</div>
</div>
</>
    );
}
export default Footer;