import { Box, Typography, keyframes } from "@mui/material";
import React, { useEffect, useState } from "react";
import '../Design/App.css';
import logo from '../Images/logo.png';
import manImage from '../Images/manImage.jpeg';
import Footer from '../Components/Footer'
import TripOriginIcon from '@mui/icons-material/TripOrigin';


function Home() {
  const flipAnimation = keyframes`
  from {
    transform: perspective(800px) rotateY(0);
  }
  to {
    transform: perspective(800px) rotateY(360deg);
  }
`;



  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 445);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div style={{ width: '100%', minHeight: '100vh', backgroundColor: '#020202' }}>

        <Box
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "space-between",
            flexDirection: isMobile ? "column" : "row",
            padding: "16px",
            borderRadius: 1,
            maxWidth: "100%",
          }}
        >
          <Box sx={{ width: isMobile ? "100%" : "40%", display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center" }}>
            <Typography
              variant={isMobile ? "h6" : "h4"}
              style={{
                color: '#ffffff',
                paddingLeft: isMobile ? '10%' : '20%',
                paddingTop: isMobile ? '' : '17vh',
                fontFamily: 'Josefin Sans'
              }}
            >
              Hello there! I'm{' '}
              <span className="animatedSpan" style={{ color: '#9703d0', fontWeight: 'bold' }}>LIRIM HAMITI</span><br></br> A passionate and dedicated<br></br>
              <span className="animatedSpan" style={{ color: '#9703d0', fontWeight: 'bold' }}>SOFTWARE DEVELOPER </span>
              with a focus on creating innovative solutions.
            </Typography>
          </Box>
          <Box sx={{ animation: `${flipAnimation} 3.5s infinite`, marginTop: isMobile ? '-6vh' : '8vh', width: isMobile ? "100%" : "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img style={{ width: '100%', height: 'auto' }} src={logo} alt="R Logo" className="logos" />
          </Box>
          <Box sx={{ marginTop: isMobile ? '-13vh' : '', width: isMobile ? "100%" : "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img style={{ width: '100%', height: 'auto' }} src={manImage} alt="R Logo" className="logos" /></Box>
        </Box>
        <hr style={{ width: "80%", marginTop: '3vh' }} />

        <Box sx={{ marginTop: '2vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ fontSize: '3vh', fontWeight: "bold" }}>SKILLS</p>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "space-between",
            flexDirection: isMobile ? "column" : "row",
            padding: "5vh",
            borderRadius: 1,
            maxWidth: "100%",
          }}
        >
          <Box sx={{ width: isMobile ? "100%" : "50%", display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center" }}>
            <p><TripOriginIcon sx={{ color: "#9703d0" }}/> .Net / .Net Core</p>
          </Box>
          <Box sx={{ width: isMobile ? "100%" : "50%", display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center" }}>
           <p> <TripOriginIcon sx={{ color: "#9703d0" }}/> Microsoft SQL</p>
          </Box>

          <Box sx={{ width: isMobile ? "100%" : "50%", display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center" }}>
           <p><TripOriginIcon sx={{ color: "#9703d0" }}/> PostgreSQL</p>
          </Box>

        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "space-between",
            flexDirection: isMobile ? "column" : "row",
            padding: "0 5vh 5vh 5vh",
            borderRadius: 1,
            maxWidth: "100%",
          }}
        >
          <Box sx={{ width: isMobile ? "100%" : "50%", display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center" }}>
            <p><TripOriginIcon sx={{ color: "#9703d0" }}/> Blazor</p>
          </Box>
          <Box sx={{ width: isMobile ? "100%" : "50%", display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center" }}>
            <p> <TripOriginIcon sx={{ color: "#9703d0" }}/> React - tsx / js</p>
          </Box>

          <Box sx={{ width: isMobile ? "100%" : "50%", display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center" }}>
            <p> <TripOriginIcon sx={{ color: "#9703d0" }}/> Blazorise | MUI</p>
          </Box>

        </Box>


        <Box
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "space-between",
            flexDirection: isMobile ? "column" : "row",
            padding: "0 5vh 5vh 5vh",
            borderRadius: 1,
            maxWidth: "100%",
          }}
        >
          <Box sx={{ width: isMobile ? "100%" : "50%", display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center" }}>
            <p><TripOriginIcon sx={{ color: "#9703d0" }}/> GraphQL</p>
          </Box>
          <Box sx={{ width: isMobile ? "100%" : "50%", display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center" }}>
            <p> <TripOriginIcon sx={{ color: "#9703d0" }}/> Rest API</p>
          </Box>

          <Box sx={{ width: isMobile ? "100%" : "50%", display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center" }}>
            <p> <TripOriginIcon sx={{ color: "#9703d0" }}/> Web Services</p>
          </Box>

        </Box>









      </div>
      <Footer />
    </>
  );
}

export default Home;