import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import '../Design/AboutMe.css';
import Footer from "../Components/Footer";




function AboutMe() {


  const [isMobile, setIsMobile] = useState(false);
useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth < 445);
  };

  handleResize();

  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

  return (
    <>
    <Box
      style={{
        backgroundColor: 'black',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: isMobile ? 'auto' : '75vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

      }}
    >
      <Box id='aboutme' style={{ paddingTop: isMobile ? "3vh": '', width: isMobile ? '80%' : '50%', height:"90%" }}>
      <h1 className="animate-charcter">ABOUT ME</h1>
      <p style={{ fontSize: isMobile ? '20px': '25px', paddingTop: isMobile ?'2vh' : '5vh', textAlign : isMobile ? "" : 'justify'}}>
        My name is  <span style={{fontWeight:'bold'}}>LIRIM HAMITI </span> and I live in Skopje, North Macedonia. I was born in 2000 and I am currently in last year of studies at Ss. Cyril and Methodius University in Skopje.
      </p>
      <p style={{paddingTop: isMobile ?'2vh' : '', fontSize: isMobile ? '20px': '25px', textAlign : isMobile ? "" : 'justify'}}>
      I am pursuing my degree in the Faculty of Computer Science and Engineering, specializing in Software Engineering and Information Systems.
      </p>
      <p style={{paddingTop: isMobile ?'2vh' : '', fontSize: isMobile ? '20px': '25px', textAlign : isMobile ? "" : 'justify' }}>
         I have gained practical experience through an internship as a full stack developer at Edusoft company in Skopje from February 2022 until May 2022.
      </p>

      <p style={{paddingTop: isMobile ?'2vh' : '',fontSize: isMobile ? '20px': '25px' , textAlign : isMobile ? "" : 'justify'}}>
      Currently, I am working as a full-stack (.Net / Blazor) developer at Edusoft company since September 2022.
      </p>
      </Box>
    </Box>
    <Footer />
    </>
  );
}

export default AboutMe;
