import '../Design/Contact.css';
import React, { useEffect, useState } from "react";
import EmailIcon from '@mui/icons-material/Email';
import Footer from '../Components/Footer'
import emailjs from '@emailjs/browser'
import ReCAPTCHA from 'react-google-recaptcha';


function Contact() {


  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 445);
    };
  
    handleResize();
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isSended, setIsSended] = useState(null)
  const [captchaValue, setCaptchaValue] = useState(null)

  const [name, setName] = useState('')
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const [email, setEmail] = useState('')
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const [subject, setSubject] = useState('')
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };
  const [message, setMessage] = useState('')
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };


  useEffect(() => {
    if (isSended) {
      const timer = setTimeout(() => {
        setIsSended(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isSended]);

  const sendEmail = (e) => {
    e.preventDefault();
    if (e.target.name.value !== '' && e.target.email.value !== '' && e.target.subject.value !== '' && e.target.message.value !== '') {
      emailjs.sendForm('service_qa0k14f', 'template_ci9uu1g', e.target, 'dryEhAtvLF3zyAfAQ').then((result) => {
        console.log(result.text)
        setIsSended(true)
      },
        (error) => {
          console.log(error.text)
        }
      )
      setIsSended(null)
      e.target.reset()
    }
    else {
      setIsSended(null)
    }
  }

  return (
    <>
      <div className="container" style={{ backgroundColor:'black', height: isMobile ? 'auto' : '80vh' }}>
        <div id="mail" className="box">
          <h3 style={{ color: 'white' }}>
            CONTACT ME
          </h3>
          <form onSubmit={sendEmail}>
            <div>
              <div className="input-container" style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Name:</label>
                <input onChange={handleNameChange} type="text" name="name" className="form-control" />
              </div>

              <div className="input-container" style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Email:</label>
                <input onChange={handleEmailChange}   type="text" name="email" className="form-control" />
              </div>

              <div className="input-container" style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Subject:</label>
                <input onChange={handleSubjectChange}  type="text" name="subject" className="form-control" />
              </div>

              <div className="input-container" style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Message:</label>
                <textarea onChange={handleMessageChange} id="message" name="message" className="form-control" />
              </div>



              <div style={{ paddingTop: "2vh", display: 'flex', flexDirection: 'column' }}>
                <input
                  id="sendButton"
                  disabled={!captchaValue}
                  type="submit"
                  value="SEND"
                  className="form-control"
                  style={{
                    backgroundColor:'#9703d0',
                    color: 'white',
                    padding: '1.5vh 2vh',
                    borderRadius: '5px',
                    marginBottom: '10px',
                    border: 'none',
                    width: '100%',
                    cursor: ( captchaValue !== null && name !== '' && subject !== '' && message !== '' && email !== '' ) ? 'pointer' : 'not-allowed' ,
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                />
                <p id="emailMsg" className={isSended ? 'show-message' : 'hide-message'}>
                  Email sent successfully!
                </p>
              </div>
                  <div style={{transform: isMobile ? "scale(0.85)" : '', transformOrigin: isMobile ? "0 0" : '', paddingTop:"3.5vh"}}>
              <ReCAPTCHA
              sitekey='6LdO-1woAAAAAKhHHflWcVh1B5ge_LLvyTycx7Rq'
              onChange={(val) => setCaptchaValue(val)}
              theme='dark'
              >
                
              </ReCAPTCHA>
              </div>
            </div>
          </form>
        </div>
        <div id="icon" className='box2' style={{marginTop: isMobile ? '8vh' : '', width:isMobile ? '' : '60vh',height: isMobile ? '300px' : '60vh'}}  >
          <EmailIcon id='emaiLogo' style={{ width: '70%', height: '100%', color: 'white', marginTop: isMobile ? '6vh': '' }} />
        </div>
        

      </div>

      <Footer />

    </>


  );
}

export default Contact;
