import './Design/App.css';
import NavBar from './Components/NavBar';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import AboutMe from './Pages/AboutMe';
import Contact from './Pages/Contact';

function App() {
  return (
<>
<NavBar/>
<Routes>
  <Route path='/' element={<Home/>}>Home</Route>
  <Route path='/About Me' element={<AboutMe/>}>AboutMe</Route>
  <Route path='/Contact' element={<Contact/>}>Contact</Route>

</Routes>
</>
  );
}

export default App;
